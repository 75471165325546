// Asynchronous component definitions for lazy-loading routes.
// https://router.vuejs.org/guide/advanced/lazy-loading.html
// https://webpack.js.org/guides/code-splitting/#dynamic-imports
// https://webpack.js.org/api/module-methods/#magic-comments
const
    HolidayPage = async () => import(
        /* webpackChunkName: 'holiday' */
        '../views/HolidayPage.vue'
    );

export default [
    { path: '/holiday', component: HolidayPage, meta: {
        public: true,
    } },
];
