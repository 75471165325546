// Asynchronous component definitions for lazy-loading routes.
// https://router.vuejs.org/guide/advanced/lazy-loading.html
// https://webpack.js.org/guides/code-splitting/#dynamic-imports
// https://webpack.js.org/api/module-methods/#magic-comments
const
    SuccessPage = async () => import(
        /* webpackChunkName: 'success' */
        '../views/SuccessPage.vue'
    );

/* export default [
    { path: '/success', component: SuccessPage, meta: {
        public: true,
        activeInPhase: ['live'],
    } },
];*/

export default [{ path: '/success', component: SuccessPage }];
