
import { mapState, mapGetters } from 'vuex';
import { page } from '../plugins/WebAnalytics';
import store from '../store';

export default {
    name: 'BasePage',

    data () {
        return {
            pageName: '',
            pageClasses: [],
        };
    },

    computed: {
        // Map state very commonly used by pages, for convenience.
        ...mapState([
            'app',
            'profile',
            'ui',
        ]),
        ...mapGetters({
            loggedIn: 'profile/loggedIn',
            isSessionExpired: 'profile/isSessionExpired',
            inPhase2: 'app/inPhase2',
            inPhase4: 'app/inPhase4',
            inPhase5: 'app/inPhase5',
            inPhase6: 'app/inPhase6',
            inPhase7: 'app/inPhase7',
            inPhase8: 'app/inPhase8',
            inPhase9: 'app/inPhase9',
            inPhase10: 'app/inPhase10',
            inPhase11: 'app/inPhase11',
            inPhase12: 'app/inPhase12',
            inPhase13: 'app/inPhase13',
            inPhase14: 'app/inPhase14',
            inPhase15: 'app/inPhase15',
            inPhase16: 'app/inPhase16',
            inPhase17: 'app/inPhase17',
            inPhase18: 'app/inPhase18',
            inPhase19: 'app/inPhase19',
            isDev: 'app/isDev',
            isTest: 'app/isTest',
            isReview: 'app/isReview',
            isProd: 'app/isProd',
        }),
    },

    beforeRouteEnter (to, from, next) {
        next(vm => {
            store.commit('ui/pageEnter', vm);

            page();
        });
    },

    mounted () {
        document.body.setAttribute('data-e2e-page', this.pageName);

        // if (!this.loggedIn) {
        //     if (this.pageName == 'success' || this.pageName == 'holiday') {
        //         window.location.href = '/oauth/redirect?page=' + this.pageName;
        //     }
        // }
    },

    beforeDestroy () {
        document.body.removeAttribute('data-e2e-page');
    },

    beforeRouteLeave (to, from, next) {
        store.commit('ui/pageLeave');

        next();
    },

    methods: {
        find (selector) {
            if (!this.$el) return;
            return this.$el.querySelector(selector);
        },

        scrollToTop () {
            document.body.scrollTop = 0;
            document.documentElement.scrollTop = 0;
        },
    },
};
