import createAnalytics, { VuePlugin } from '@roc/web-analytics';
import store from '../store';

// Create instance with access to vuex store
const
    webAnalytics = createAnalytics({ store }),
    { page, track } = webAnalytics;

export default webAnalytics;
export { page, track, VuePlugin };
