
import store from '../store/index';

// Asynchronous component definitions for lazy-loading routes.
// https://router.vuejs.org/guide/advanced/lazy-loading.html
// https://webpack.js.org/guides/code-splitting/#dynamic-imports
// https://webpack.js.org/api/module-methods/#magic-comments
const
    DuplicateAccountPage = async () => import(
        /* webpackChunkName: 'profile' */
        '../views/DuplicateAccountPage.vue'
    ),
    IneligibleAgePage = async () => import(
        /* webpackChunkName: 'profile' */
        '../views/IneligibleAgePage.vue'
    ),
    LimiterPage = async () => import(
        /* webpackChunkName: 'profile' */
        '../views/LimiterPage.vue'
    ),
    LoginPage = async () => import(
        /* webpackChunkName: 'profile' */
        '../views/LoginPage.vue'
    ),
    RegisterPage = async () => import(
        /* webpackChunkName: 'profile' */
        '../views/RegisterPage.vue'
    ),
    ExchangeLandingPage = async () => import(
        /* webpackChunkName: 'profile' */
        '../views/ExchangeLandingPage.vue'
    ),
    ExchangeConfirmationPage = async () => import(
        /* webpackChunkName: 'profile' */
        '../views/ExchangeConfirmationPage.vue'
    ),
    ExchangeRewardsHubPage = async () => import(
        /* webpackChunkName: 'profile' */
        '../views/ExchangeRewardsHubPage.vue'
    );
    // SurveyPoll = async () => import(
/* webpackChunkName: 'profile' */
//  '../views/SurveyPoll.vue'
    // );

export default [
    { path: '/', redirect: (to) => {
        // Used to send server-side errors to the '/error' page.
        if (store.state.app.defaultRoute) {
            return store.state.app.defaultRoute;
        }
        // Default routes for logged-in users.
        else if (
            store.getters['profile/loggedIn'] &&
            !store.getters['profile/isSessionExpired']
        ) {
            console.log('logged in');
            return '/success';
        }
        // Default routes for returning users (reg cookie) who are not logged-in.
        // else if (store.getters['profile/returning']) {
        //     return '/login';
        // }

        // Default route for a user we know nothing about.
        return '/register';
    } },
    { path: '/register', component: RegisterPage, meta: { public: true } },
    { path: '/limiter', component: LimiterPage },
    // { path: '/survey', component: SurveyPoll },
    { path: '/ineligible_age', component: IneligibleAgePage, meta: { public: true } },
    { path: '/dup_account', component: DuplicateAccountPage, meta: { public: true } },
    { path: '/exchange_hub',  component: ExchangeRewardsHubPage },
    { path: '/exchange_landing', component: ExchangeLandingPage },
    { path: '/exchange_confirmation', component: ExchangeConfirmationPage },
];
