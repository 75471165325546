import axios from 'axios';
import Vue from 'vue';
import api from '../../api';

const state = {
    tier: '',
    coupons: [],
    rewardsAvailable: [],
    availablePoints: 0,
};

const mutations = {
    updateRewards (state, data) {
        // Set Tier
        Vue.set(state, 'tier', data.tier);

        // Set rewardsAvailable available
        Vue.set(state, 'rewardsAvailable', data.rewardsAvailable);

        // Only set coupons if user has a coupon to use
        if (data.coupons[0]) {
            Vue.set(state, 'coupons', data.coupons);
        }

        // Set availablePoints
        Vue.set(state, 'availablePoints', data.availablePoints);
    },
};
const actions = {
    async getLoyaltyUser ({ commit }, red_lobster_id) {
        try {
            const { data } = await axios.post(`${api.base}/loyalty/getUser`, {
                red_lobster_id,
            });
            const formattedLpUser = formatLpUser(data);
            await commit('updateRewards', formattedLpUser);
            return formattedLpUser;
        }
        catch (err) {
            console.error(err);
            throw err;
        }
    },
    // Call this from the exchange landing page with an array of the codes of selected coupons,
    // the id of the reward in the rewards obj, and the user's red_lobster_id
    async exchangeReward ({ commit }, data) {
        const { red_lobster_id, coupon_codes, reward_id } = data;

        try {
            const success = await axios.post(`${api.base}/loyalty/updateAndReward`, {
                red_lobster_id, coupon_codes, reward_id,
            });
            return success;
        }
        catch (err) {
            console.error(err);
            throw err;
        }
    },
};

function formatLpUser (data) {
    const filteredRewards = data.customer_rewards.filter(reward => reward.pos_code === 'Stackable').map(reward => ({
        id: reward.id,
        name: reward.name,
        cost: reward.cost,
        instructions: reward.instructions,
        pos_code: reward.pos_code,
        description: reward.description,
        image_url: reward.image_url,
    }));

    const formattedCoupons = data.customer_coupons.filter(coupon =>
        coupon.reward.pos_code === 'Reward' &&
        coupon.status === 'redeemed' &&
        new Date(coupon.start_at).getTime() < Date.now() &&
        coupon.reward.points === 125)
    .map(coupon => ({
        code: coupon.code,
        name: coupon.reward.name,
        points: coupon.reward.points,
        description: coupon.reward.description,
        image_url: coupon.reward.image_url,
        redeem_instructions: coupon.reward.redeem_instructions,
        expires_at: coupon.expires_at,
    }));

    const claimedRewards = data.customer_coupons.filter(coupon =>
        coupon.reward.pos_code === 'Stackable' &&
        new Date(coupon.start_at).getTime() < Date.now() &&
        (parseInt(coupon.reward.points) > 125 || parseInt(coupon.reward.cost) > 125))
    .map(coupon => ({
        code: coupon.code,
        name: coupon.reward.name,
        cost: coupon.reward.cost,
        description: coupon.reward.description,
        image_url: coupon.reward.image_url,
        redeem_instructions: coupon.reward.redeem_instructions,
        expires_at: coupon.expires_at,
        redeemed_at: coupon.redeemed_at,
    }));

    return {
        tier: data.top_tier_name,
        rewardsAvailable: filteredRewards,
        coupons: formattedCoupons,
        claimedRewards,
        availablePoints: data.member_attributes.available_reward_cost,
    };
}

export default {
    namespaced: true,
    state,
    mutations,
    actions,
};
